/**
 * Sentry logs errors occuring in the browser, so we can track problems in
 * our front-end components.
 */

import {init, browserTracingIntegration, captureConsoleIntegration} from '@sentry/vue';

type Options = Exclude<Parameters<typeof init>[0], undefined>;

const SentryIntegrations = [
  browserTracingIntegration(),
  captureConsoleIntegration({
    levels: ['warn', 'error'],
  }),
];

const setupSentry = async(Vue: Options['Vue'], options: Options) => {
  init({
    Vue,
    integrations: SentryIntegrations,
    ...options,
  });
};

window.setupSentry = setupSentry;

declare global {
  interface Window {
    setupSentry: typeof setupSentry;
  }
}
